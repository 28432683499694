import { http } from "~/src/services/requests";

const TERM_ACTIONS = {
  ACCEPTED: "accepted",
  REVIEW_LATER: "review_later"
};

function apiService() {
  return {
    async getTermsStatus(termsSlug) {
      const parse = true;
      return await http.innerSendAuthenticated({
        relativePath: `/termsofservice/api/v1/terms/${termsSlug}/status`,
        method: "GET"
      }, parse);
    },
    async getTermsOfService(termsSlug) {
      const parse = true;
      return await http.innerSendAuthenticated({
        relativePath: `/termsofservice/api/v1/terms/${termsSlug}/`,
        method: "GET"
      }, parse);
    },
    async createTermsEvent(termsSlug, action) {
      const parse = false;
      return await http.innerSendAuthenticated({
        relativePath: `/termsofservice/api/v1/terms/${termsSlug}/event/`,
        method: "POST",
        body: { action }
      }, parse);
    }
  };
}

const api = apiService();

export async function getTermsStatus({ termsSlug }) {
  try {
    return await api.getTermsStatus(termsSlug);
  } catch (error) {
    return false;
  }
}

export async function getTermsOfService({ termsSlug }) {
  try {
    return await api.getTermsOfService(termsSlug);
  } catch (error) {
    return false;
  }
}

export async function acceptTerms({ termsSlug }) {
  try {
    await api.createTermsEvent(termsSlug, TERM_ACTIONS.ACCEPTED);
    return true;
  } catch (error) {
    return false;
  }
}
