import * as React from "react";
import PropTypes from "prop-types";
import { css } from "aphrodite";

import styles from "./styles";

const Card = ({
  className,
  cssStyle,
  large,
  small,
  dynamic,
  children,
  renderHeader,
  renderFooter,
  renderActions,
  elevate,
  noPadding,
}) => (
  <div
    className={`${css(
      styles.card,
      small && styles.cardSmall,
      large && styles.cardLarge,
      elevate && styles.cardElevate,
      elevate === 1 && styles.cardElevateOne,
      dynamic && styles.cardDynamic,
      cssStyle
    )} ${className}`}
  >
    {renderHeader && (
      <div
        className={css(
          styles.cardHeader,
          noPadding && styles.cardHeaderNoPadding
        )}
      >
        {renderHeader()}
      </div>
    )}
    <div className={css(styles.cardBody, noPadding && styles.cardNoPadding)}>
      {children}
    </div>
    {renderActions && (
      <div className={css(styles.cardActions)}>{renderActions()}</div>
    )}
    {renderFooter && (
      <div
        className={css(
          styles.cardFooter,
          noPadding && styles.cardFooterNoPadding
        )}
      >
        {renderFooter()}
      </div>
    )}
  </div>
);

Card.defaultProps = {};
Card.propTypes = {
  renderActions: PropTypes.func,
  renderHeader: PropTypes.func,
  renderFooter: PropTypes.func,
};

export default Card;
