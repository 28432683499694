export default {
  family: `-apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue",
    "Helvetica", sans-serif`,
  size: {
    extraSmall: "12px",
    small: "14px",
    regular: "16px",
    large: "18px"
  }
};
