export const messageTypes = {
  DOCUMENT_SCROLLED_TO_BOTTOM: "document-scrolled-to-bottom",
  DOCUMENT_SCROLLED: "document-scrolled",
  DOCUMENT_TERMS_ACCEPTED: "document-terms-accepted",
  DOCUMENT_TERMS_UNACCEPTED: "document-terms-unaccepted",
  DOCUMENT_TERMS_ACCEPTANCE_SUBMITTED: "document-terms-acceptance-submitted",
};

export const documentTypes = {
  PRIVACY_POLICY: "privacy-policy",
  TERMS_OF_USE: "terms-of-use",
};
