import cookieUtil from "~/src/utils/cookies";
import env from "~/src/utils/env";

const httpRequests = ({ apiUrl }) => {
  async function innerSend({
    host = apiUrl,
    relativePath,
    method,
    body = null,
    headers = {}
  }) {
    const requestUrl = `${host}${relativePath}`;
    let response;

    if (body) {
      headers["Content-Type"] = "application/json";
      body = JSON.stringify(body);
    }

    try {
      response = await fetch(requestUrl, {
        body,
        headers,
        method
      });

      // if (response.status === 405) {
      //   window.location.reload();
      // } else if (response.status >= 300) {
      //   throw new Error(`${response.status}`);
      // }

    } catch (err) {
      console.error(`Error requesting ${requestUrl}`, err);
      throw err;
    }

    return response;
  }

  async function innerSendAuthenticated(params, parse = false) {
    let headers = cookieUtil.getHeaderTokens();

    if (params.headers) {
      headers = Object.assign(headers, params.headers);
    }

    let response;
    try {
      response = await innerSend({
        host: apiUrl,
        ...params,
        headers
      });
    } catch (error) {
      if (response.status === 401) {
        // TODO: Handle case
        console.log("Unauthenticated");
      }
      throw error;
    }

    return parse ? await response.json() : response;
  }

  async function innerSendAndParse(params) {
    const response = await innerSend({
      host: apiUrl,
      ...params
    });
    return await response.json();
  }

  return {
    innerSend,
    innerSendAndParse,
    innerSendAuthenticated
  };
};

export const http = httpRequests({
  apiUrl: env.apiUrl
});
