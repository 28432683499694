import React, { useEffect, useState } from "react";
import { documentTypes, messageTypes } from "~/src/utils/constants";
import TermsOfServiceForm from "~/src/components/TermsOfServiceForm";
import {
  acceptTerms,
  getTermsOfService,
  getTermsStatus,
} from "~/src/services/terms";

import moment from "moment";

import "./App.css";

import { ReactComponent as ReactLogo } from "./cliodraft-logo-icon.svg";

const DOC_TYPE_TO_TERMS_SLUG = {
  [documentTypes.TERMS_OF_USE]: "lawyaw-terms-of-use",
  [documentTypes.PRIVACY_POLICY]: "lawyaw-privacy-policy",
};

function App() {
  const [documentType, setDocumentType] = useState(documentTypes.TERMS_OF_USE);
  const [termsOfService, setTermsOfService] = useState({});
  const [termsOfServiceDueDate, setTermsOfServiceDueDate] = useState(null);
  const [documentIndex, setDocumentIndex] = useState(0);

  useEffect(async () => {
    const termsSlug = DOC_TYPE_TO_TERMS_SLUG[documentType];
    const termsStatus = await getTermsStatus({ termsSlug });
    if (!termsStatus) {
      return;
    }

    // if (termsStatus.accepted) {
    //   window.location = "/clients";
    // }

    const dueDate = new Date(termsStatus.accept_due_date);
    setTermsOfServiceDueDate(dueDate);
  }, []);

  useEffect(async () => {
    const termsSlug = DOC_TYPE_TO_TERMS_SLUG[documentType];
    const termsOfService = await getTermsOfService({ termsSlug });
    setTermsOfService(termsOfService);
  }, [documentType]);

  const handleMessage = async (type, payload) => {
    if (type === messageTypes.DOCUMENT_TERMS_ACCEPTANCE_SUBMITTED) {
      await handleAcceptTerms();
      if (documentType === documentTypes.TERMS_OF_USE) {
        setDocumentType(documentTypes.PRIVACY_POLICY);
        setDocumentIndex(documentIndex + 1);
      } else if (documentType === documentTypes.PRIVACY_POLICY) {
        window.location = "/clients";
      }
    }
  };

  const handleAcceptTerms = async () => {
    const termsSlug = DOC_TYPE_TO_TERMS_SLUG[documentType];
    await acceptTerms({ termsSlug });
  };

  const reviewLaterRedirectUrl = `terms/${DOC_TYPE_TO_TERMS_SLUG[documentType]}/review-later`;

  const currentDateTime = new Date();
  console.log(termsOfServiceDueDate);
  const timeRemaining = termsOfServiceDueDate - currentDateTime;
  const canReviewLater = timeRemaining > 0;
  const remainingDays = parseInt(moment.duration(timeRemaining).asDays(), 10);
  const termsOfServiceMarkdown =
    termsOfService.text &&
    termsOfService.text.replace(/\n/gi, "  \n  \n&nbsp;");

  return (
    <div className="App">
      <div className="App-icon">
        <a href="https://lawyaw.com">
          <ReactLogo aria-label="Clio Draft: Learn more" />
        </a>
      </div>
      <div className="container">
        <div className="terms-container">
          <TermsOfServiceForm
            key={documentType}
            onMessage={handleMessage}
            documentType={documentType}
            documentIndex={documentIndex}
            documentMarkdown={termsOfServiceMarkdown}
          />
        </div>
        {canReviewLater && (
          <div className="text-button">
            <a href={reviewLaterRedirectUrl}>Review later</a>
            <p className="text-button-description">
              You have <b>{remainingDays}</b> more days to accept the terms.
            </p>
          </div>
        )}
        {!canReviewLater && (
          <div>
            <p className="text-button-description">
              You must accept the Terms of Use and Privacy Policy to continue.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
