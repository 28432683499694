import { StyleSheet } from "aphrodite";

export default StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    flex: "1 1",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
  title: {
    textAlign: "center",
    paddingBottom: 20,
    fontWeight: "bold",
    fontSize: "21px",
  },
  description: {
    textAlign: "left",
    paddingBottom: 20,
    lineHeight: "1.25em",
  },
  documentViewer: {
    height: 0,
    flex: "1 1 auto",
    overflowY: "auto",
    padding: 10,
    border: "1px solid #888",
    borderRadius: 4,
  },
  actionBar: {
    "margin-top": 20,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  button: {
    marginLeft: "20px",
  },
  markdown: {
    textAlign: "left",
    whiteSpace: "pre-wrap",
  },
});
