import { StyleSheet } from "aphrodite";
import theme from "../Theme";

export default StyleSheet.create({
  card: {
    boxSizing: "border-box",
    margin: "0 auto",
    background: theme.surface,
    border: theme.border,
    borderRadius: theme.borderRadius,
    width: theme.unit * 70,
    display: "flex",
    flexFlow: "column",
  },
  cardElevate: {
    // border: "none",
    boxShadow: theme.boxShadow,
  },
  cardElevateOne: {
    boxShadow: theme.boxShadowOne,
  },
  cardSmall: {
    maxWidth: theme.unit * 54,
  },
  cardLarge: {
    maxWidth: theme.unit * 110,
  },
  cardDynamic: {
    width: "100%",
    maxWidth: "100%",
  },
  cardNoPadding: {
    padding: 0,
  },
  cardTitle: {
    fontSize: theme.fonts.size.large,
    marginBottom: theme.unit * 4,
  },
  cardTitleBold: {
    fontWeight: "bold",
  },
  cardHeader: {
    padding: theme.unit * 3,
    borderBottom: theme.border,
  },
  cardBody: {
    flexGrow: 1,
    display: "flex",
    flexFlow: "column",
    padding: theme.unit * 3,
  },
  cardActions: {
    display: "flex",
    justifyContent: "flex-end",
    padding: theme.unit * 3,
    paddingTop: 0,
  },
  cardFooter: {
    padding: theme.unit * 3,
    borderTop: theme.border,
  },
  cardAction: {
    display: "inline-block",
    marginRight: theme.unit * 2,
    ":last-of-type": {
      marginRight: 0,
    },
  },
  cardBreak: {
    width: theme.unit * 70,
    height: "1px",
    background: theme.colors.smoke,
    display: "block",
    marginLeft: -theme.unit * 3,
    marginRight: -theme.unit * 3,
  },
});
