import Cookies from "js-cookie";

const CSRF_KEY = "csrftoken";
const AUTH_KEY = "authToken";
const ACCESS_KEY = "accessToken";

const COOKIE_KEY_MAP = {
  "X-CSRFToken": CSRF_KEY,
};

const getHeaderTokens = () => {
  let headers = {};
  for (const headerKey in COOKIE_KEY_MAP) {
    const cookieValue = Cookies.get(COOKIE_KEY_MAP[headerKey]);
    headers[headerKey] = cookieValue;
  }

  return headers;
};

export default { getHeaderTokens };
