import React from "react";
import PropTypes from "prop-types";
import { css } from "aphrodite";

import theme from "../Theme";

import styles from "./styles";

const Button = ({
  disabled,
  children,
  cssStyle,
  className,
  primary,
  success,
  alert,
  secondaryAlert,
  discrete,
  Icon,
  onClick,
}) => {
  const iconButton = Icon && !children;
  const iconSize = iconButton ? theme.fonts.size.large : theme.fonts.size.small;

  return (
    <button
      onClick={(!disabled && onClick) || undefined}
      className={`${css(
        styles.button,
        primary && styles.buttonPrimary,
        success && styles.buttonSuccess,
        disabled && styles.buttonDisabled,
        discrete && styles.buttonDiscrete,
        alert && styles.buttonAlert,
        secondaryAlert && styles.buttonSecondaryAlert,
        iconButton && styles.buttonIconButton,
        cssStyle
      )} ${className}`}
    >
      {Icon && (
        <i
          className={css(
            styles.buttonIcon,
            iconButton && styles.buttonIconCenter
          )}
        >
          <Icon size={iconSize} />
        </i>
      )}
      {children}
    </button>
  );
};

Button.propTypes = {
  primary: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  success: PropTypes.bool,
  alert: PropTypes.bool,
  secondaryAlert: PropTypes.bool,
  discrete: PropTypes.bool,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  primary: false,
  disabled: false,
  className: "",
  success: false,
  alert: false,
  secondaryAlert: false,
  discrete: false,
  onClick: () => {},
};

export default Button;
