import colors from "./colors";
import fonts from "./fonts";

export const theme = {
  background: colors.ice,
  surface: colors.white,
  border: `solid 1px ${colors.smoke}`,
  borderRadius: "4px",
  boxShadow: "0px 5px 6px 0px rgba(26,29,35,0.04)",
  boxShadowOne: "0px 2px 3px 0px rgba(26,29,35,0.02)",
  fonts,
  colors,
  unit: 8
};

export default theme;
