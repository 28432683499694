import { render } from "preact";
import "preact/debug";

import "./components/Theme/reset.css";

import App from "./App";

import * as serviceWorker from "./serviceWorker";

// Sentry.onLoad(() => {
//   try {
//     Sentry.init({
//       dsn: "https://1eb4334e35ea4c2ea5ab1c2564242c8d@sentry.io/1550451",
//       release: process.env.COMMIT_SHA,
//       environment: process.env.NODE_ENV
//     });
//   } catch (error) {
//     console.error("Sentry failed to initialize", error);
//   }
//
//   window.onerror = (error) => {
//     Sentry.captureException(error);
//   }
// });

render(<App/>, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
