// Terms of Service
import React, { useReducer } from "react";
import ReactTooltip from "react-tooltip";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import { css } from "aphrodite";

import Card from "~/src/components/Card";
import Button from "~/src/components/Button";

import styles from "./styles";

import { messageTypes, documentTypes } from "../../utils/constants";

const documents = {
  [documentTypes.TERMS_OF_USE]: {
    displayName: "Terms of Use",
  },
  [documentTypes.PRIVACY_POLICY]: {
    displayName: "Privacy Policy",
  },
};

const initialState = {
  acceptCheckboxDisabled: true,
  continueButtonDisabled: true,
  termsAccepted: false,
  acceptanceSubmitted: false,
};

const reducer = (state, action) => {
  if (state.acceptanceSubmitted) {
    return state;
  }
  switch (action.type) {
    case messageTypes.DOCUMENT_SCROLLED:
      return {
        ...state,
        acceptCheckboxDisabled: true,
        continueButtonDisabled: true,
      };
    case messageTypes.DOCUMENT_SCROLLED_TO_BOTTOM:
      return {
        ...state,
        acceptCheckboxDisabled: false,
        continueButtonDisabled: !state.termsAccepted,
      };
    case messageTypes.DOCUMENT_TERMS_ACCEPTED:
      return {
        ...state,
        termsAccepted: true,
        continueButtonDisabled: false,
      };
    case messageTypes.DOCUMENT_TERMS_UNACCEPTED:
      return {
        ...state,
        termsAccepted: false,
        continueButtonDisabled: true,
      };
    case messageTypes.DOCUMENT_TERMS_ACCEPTANCE_SUBMITTED:
      return {
        ...state,
        acceptanceSubmitted: true,
        acceptCheckboxDisabled: true,
        continueButtonDisabled: true,
      };
    default:
      return state;
  }
};

const isScrolledToBottom = (target) => {
  const scrollHeight = target.scrollHeight;
  const scrollTop = target.scrollTop;
  const offsetHeight = target.offsetHeight;
  const scrolledToBottom = scrollTop + offsetHeight > scrollHeight;
  return scrolledToBottom;
};

export default ({
  documentType = documentTypes.TERMS_OF_USE,
  documentMarkdown,
  documentIndex = 0,
  documentCount = 2,
  onMessage = () => {},
}) => {
  const [state, _dispatch] = useReducer(reducer, initialState);

  const document = documents[documentType];

  const dispatch = (type, payload = {}) => {
    _dispatch({ type, payload });
    onMessage(type, { ...payload, documentType });
  };

  const handleScroll = (event) => {
    if (isScrolledToBottom(event.target)) {
      dispatch(messageTypes.DOCUMENT_SCROLLED_TO_BOTTOM);
    } else {
      dispatch(messageTypes.DOCUMENT_SCROLLED);
    }
  };

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      dispatch(messageTypes.DOCUMENT_TERMS_ACCEPTED);
    } else {
      dispatch(messageTypes.DOCUMENT_TERMS_UNACCEPTED);
    }
  };

  const handleClickContinue = () => {
    dispatch(messageTypes.DOCUMENT_TERMS_ACCEPTANCE_SUBMITTED);
  };

  return (
    <Card className={css(styles.container)} elevate>
      <h1 className={css(styles.title)}>Clio Draft {document.displayName}</h1>
      <p className={css(styles.description)}>
        Hey there! We need to ask you to take a moment to review and accept our
        updated Privacy Policy and Terms of Use before you can continue using
        Clio Draft.
      </p>
      <div onScroll={handleScroll} className={css(styles.documentViewer)}>
        <ReactMarkdown
          className={css(styles.markdown)}
          plugins={[gfm]}
          children={documentMarkdown}
        />
      </div>
      <div className={css(styles.actionBar)}>
        <label data-tip="Please scroll to the bottom of the document first">
          <input
            disabled={state.acceptCheckboxDisabled}
            onChange={handleCheckboxChange}
            type="checkbox"
          />
          I accept the {document.displayName}. (Document {documentIndex + 1} of{" "}
          {documentCount})
        </label>
        {state.acceptCheckboxDisabled && (
          <ReactTooltip place="top" type="dark" />
        )}
        <Button
          className={css(styles.button)}
          onClick={handleClickContinue}
          disabled={state.continueButtonDisabled}
        >
          Continue
        </Button>
      </div>
    </Card>
  );
};
